import tableColumnsCache from "@/utils/tableColumnsCache"

const listVersion = 3
export const listName = 'sl.propostas-venda-direta.lista'
export const listStorage = tableColumnsCache(listName, [
    {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
    {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 2},
    {label: 'Processo', name: 'processo', active: true, sortable: true, ordering: 2},
    {label: 'Comitente', name: 'comitente', active: true, sortable: true, ordering: 2},
    {label: 'Data', name: 'data', active: true, sortable: true, ordering: 3},
    {label: 'E-mail', name: 'email', active: true, sortable: true, ordering: 4},
    {label: 'Telefone', name: 'telefone', active: true, sortable: true, ordering: 5},
    {label: 'Bem', name: 'bem', active: true, sortable: true, ordering: 5},
    {label: 'Valor', name: 'valor', active: true, sortable: true, ordering: 5},
    {label: 'Status', name: 'status', active: true, sortable: true, ordering: 6},
], listVersion)
