<script>
import {list, deleteProposta} from '@/domain/bem/services/propostas'
import {date} from 'uloc-vue'
import {datePtToEn} from '@/utils/date'
import {ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import DefaultMixin from '../mixin'
import DateInput from "@/reuse/input/Date"
import {listName, listStorage} from "../config/list"
import ListMixin from "@/reuse/list/ListMixin"
import GlobalPersonMixin from "@/components/pessoa/globalPersonMixin"

const filters = {
  id: null,
  active: true,
  deleted: false,
  search: null,
  status: 0
}

export default {
  name: 'PropostasVendaDireta',
  mixins: [DefaultMixin, ListMixin, GlobalPersonMixin],
  components: {
    DateInput,
    ErpInput,
    ErpSField,
    ErpSelect
  },
  data() {
    const _filters = JSON.parse(JSON.stringify(filters))
    return {
      listName: listName,
      listType: 'a',
      filters: _filters
    }
  },
  watch: {
    'filters.status' (v) {
      this.$nextTick(() => {
        this.pesquisar()
      })
    }
  },
  computed: {
  },
  beforeCreate() {
    this.listStorage = listStorage
  },
  mounted() {
    this.load()
  },
  destroyed() {
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  methods: {
    request({pagination, filter}) {

      // this.table.serverPagination.rowsNumber = 0

      let data1, data2
      let extraFilters = []

      if (this.filters.data1) {
        if (this.filters.data1.length < 10) {
          alert('Digite a data inicial e data final corretamente para o filtro de entrada.')
          return
        }

        data1 = datePtToEn(this.filters.data1)
        data2 = this.filters.data2 ? datePtToEn(this.filters.data2) : data1
        if (!date.isValid(data1) || !date.isValid(data2)) {
          alert('Digite a data inicial e data final corretamente. Formato: dd/mm/yyyy')
          return
        }
        extraFilters.push(`&data1=${data1}&data2=${data2}`)
      }

      this.filters.tipoData && extraFilters.push(`&typeDate=${this.filters.tipoData}`)

      this.filters.id && extraFilters.push(`&id=${this.filters.id}`)
      this.filters.search && extraFilters.push(`&search=${this.filters.search}`)

      if (Array.isArray(this.filters.status) && this.filters.status.length > 0) {
        extraFilters.push(`&status=${this.filters.status.join(',')}`)
      }

      this.filters.bem && extraFilters.push(`&bem=${this.filters.bem.id || this.filters.bem}`)
      this.filters.pessoa && extraFilters.push(`&pessoa=${this.filters.pessoa.id || this.filters.pessoa}`)
      this.filters.status !== null && extraFilters.push(`&status=${this.filters.status}`)

      this.table.loading = true
      let _filter = this.filtros
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&${extraFilters.join('')}`)

      list(null, pagination.rowsPerPage, pagination.page, filtros)
          .then(({data}) => {
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.stats = data.stats
            if (data.counts) {
              this.counts = data.counts
            }

            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    novo(t = null) {
      console.log('New...')
      this.windowVistoria(null)
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.filters.descricao || this.table.busca
      })
    },
    abrir(id) {
      this.$router.push({name: 'propostas-venda-direta.show', params: {id: id}})
    },
    edit(id) {
      this.$router.push({name: 'propostas-venda-direta.show', params: {id: id}})
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este registro? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir ' + id)
          .then(() => {
            this.$uloc.loading.show()
            deleteProposta(id)
                .then(() => {
                  this.$uloc.loading.hide()
                  this.$uloc.notify({
                    color: 'positive',
                    message: `Conta excluída com sucesso.`
                  })
                  this.load()
                })
                .catch(error => {
                  this.$uloc.loading.hide()
                  this.alertApiError(error)
                })
          })
          .catch(() => {
          })
    },
    label(a, b) {
      return a
    }
  },
  meta: {
    title: 'Propostas',
    name: 'Propostas'
  }
}
</script>

<template>
  <div class="vistoria-container wrapper-md">
    <e-row class="m-b-md">
      <e-col style="max-width: 350px">
        <h5 class="">Propostas</h5>
        <!--<resumo v-if="!isEmbedComponent" :stats="stats" :isEmbedComponent="isEmbedComponent" />-->
      </e-col>
      <!--        <e-col class="flex items-start justify-end col-grow-1">
                <div class="saldo-contas font-12 font-avenir text-grey-7 flex column m-l-xxl">
                  <div class="at-title m-b">PERÍODO</div>
                  <ul class="menu-alfabeto bold flex column" style="margin-top: 0; margin-left: -8px">
                    <li v-for="filtro in filtroPeriodos" :key="filtro.value"><a @click="buscaPorPeriodo(filtro)" :class="{active: filtro.active}">{{ filtro.name || filtro.value }}</a></li>
                  </ul>
                </div>
              </e-col>-->
      <e-col class="flex items-center justify-center col-grow-1">
        <!--          <ul class="menu-alfabeto bold m-l-xxl">
                    <li v-for="filtro in filtroPeriodos" :key="filtro.value"><a @click="buscaPorPeriodo(filtro)" :class="{active: filtro.active}">{{ filtro.name || filtro.value }}</a></li>
                  </ul>-->
        <!-- <fin-filtros-adicionais class="m-r" :filters="filters" :stats="stats" />
        <filtro-conta :stats="stats" />
        <filtro-periodo :stats="stats" @mudarPeriodo="buscaPorPeriodo" @addsub="addsubPeriodo" :periodos="filtroPeriodos" :filters="filters" class="m-l-lg" />-->
      </e-col>
      <e-col class="justify-end flex" style="max-width: 200px">
        <!--<fin-saldo-contas @new="(t) => novo(t)" :type="listType" :stats="stats" :isEmbedComponent="isEmbedComponent" />-->
        <!--          <u-btn @click="windowVistoria" no-caps color="green" class="app-btn font-12 no-border-radius-right">Registrar vistoria</u-btn>-->
        <!-- @TODO -->
      </e-col>
    </e-row>
    <div class="erp-list list-tasks no-pad">
      <div class="erp-list-top-btns items-end">
<!--        <div class="actives-btns col-grow-1">
          <u-btn @click="changeListType('t')" :label="label('Todas', 't')" class="active-table-btn"
                 :class="{active: listType === 't'}" no-caps/>
          <u-btn @click="changeListType('a')" :label="label('Ativas', 'a')" class="active-table-btn"
                 :class="{active: listType === 'a'}" no-caps/>
          <u-btn @click="changeListType('f')" :label="label('Finalizadas', 'f')" class="active-table-btn"
                 :class="{active: listType === 'f'}" no-caps/>
        </div>-->
        <div class="end font-14 bg-white flex-zero flex no-wrap">
          <e-col style="min-width: 170px" class="m-l m-r">
            <erp-s-field
                view="ll"
                label="Buscar"
            >
              <erp-input shortkey="F3" v-model="filters.search" @keydown.enter="pesquisar"/>
            </erp-s-field>
          </e-col>
          <e-col style="min-width: 150px" class="m-r">
            <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (De)"
                        v-model="filters.data1"/>
          </e-col>
          <e-col style="min-width: 150px" class="m-r">
            <date-input @keydown.enter.native="pesquisar" field-view="ll" label="Data (Até)"
                        v-model="filters.data2"/>
          </e-col>
          <e-col style="min-width: 150px" class="m-r">
            <erp-select v-model="filters.status" placeholder="Qualquer" :options="[
                {label: 'Qualquer', value: null},
                {label: 'Recebido', value: 0},
                {label: 'Em análise', value: 1},
                {label: 'Reprovada', value: 99},
                {label: 'Aprovada', value: 100},
            ]" />
          </e-col>
          <!--              <e-col style="min-width: 150px">
                          <erp-s-field
                              view="ll"
                              label="Filtro Data"
                          >
                            <erp-select
                                :options="[
                                    {label: 'Criação', value: 'createdAt'},
                                    {label: 'Início', value: 'startTime'},
                                    {label: 'Prazo', value: 'deadline'},
                                ]"
                                v-model="filters.tipoData" />
                          </erp-s-field>
                        </e-col>
                        <e-col style="min-width: 150px">
                          <status-task-select field-view="ll" multiple v-model="filters.status" @change="load()" code-as-value />
                        </e-col>-->

        </div>
      </div>
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="erp-table erp-table-options table-default"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th auto-width>
            <u-checkbox
                v-model="props.selected"
                :indeterminate="props.partialSelected"
                size="sm"
            />
          </u-th>
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
              <u-btn style="margin: -10px 0 -6px" @click="listOptions" label="Configurar lista" rounded round flat
                     no-caps size="xs" icon="cog" icon-type="fa" color="grey-4" class="text-grey-8"/>
            </div>
          </u-th>
        </u-tr>

        <u-tr v-on:dblclick.native="abrir(props.row.id)" slot="body" slot-scope="props"
              :props="props" style="cursor: default">
          <u-td auto-width>
            <u-checkbox color="primary" v-model="props.selected" size="xs"/>
          </u-td>
          <u-td key="id" :props="props">
            {{ props.row.id }}
          </u-td>
          <u-td key="nome" :props="props">
            <div v-if="props.row.pessoa">
              <a @click="gotoPerson(props.row.pessoa)">{{props.row.pessoa.name}}</a> <span class="badge badge-sm bg-blue-grey-1 text-blue-grey-5"><i class="fa fa-user" /><u-tooltip :offset="[4,4]">Autenticado</u-tooltip></span>
            </div>
            <div v-else>{{ props.row.nome }}</div>
          </u-td>
          <u-td key="processo" :props="props">
            <div v-if="props.row.bem">{{ props.row.bem.processoNumero|formataNumeroProcesso }}</div>
          </u-td>
          <u-td key="comitente" :props="props">
            <div v-if="props.row.bem">{{ props.row.bem.comitente.pessoa.name }}</div>
          </u-td>
          <u-td key="data" :props="props">
            {{ props.row.createdAt|formatDate }}
          </u-td>
          <u-td key="email" :props="props">
            {{ props.row.email }}
          </u-td>
          <u-td key="telefone" :props="props">
            {{ props.row.telefone }}
          </u-td>
          <u-td key="bem" :props="props" class="td-limit l-130">
            <div v-if="props.row.bem">{{ props.row.bem.siteTitulo }}</div>
            <div v-else>{-</div>
          </u-td>
          <u-td key="valor" :props="props">
            <div v-if="!props.row.valor">Não informado</div>
            <div v-else>R$ {{ props.row.valor|moeda }}</div>
          </u-td>
          <u-td key="status" :props="props">
            <div v-if="props.row.status === 0">Recebida</div>
            <div v-if="props.row.status === 1" class="text-primary">Em análise</div>
            <div v-if="props.row.status === 99" class="text-negative">Recusado</div>
            <div v-if="props.row.status === 100" class="font-bold text-positive">Aceita</div>
          </u-td>
          <u-td class="text-center" key="options" :props="props">
            <e-btn-table-options>
              <menu-options>
                <list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                                @excluir="excluir(props.row.id)" :leilao="props.row"/>
              </menu-options>
            </e-btn-table-options>
          </u-td>
        </u-tr>
      </u-table>

      <!--<div class="flex justify-between">
        <div class="m-t">
          <u-btn icon="print" color="blue-grey-6" flat round no-caps>
            <u-tooltip :offset="[5,5]">Imprimir</u-tooltip>
          </u-btn>
          <u-btn icon="file_upload" color="blue-grey-6" flat round no-caps>
            <u-tooltip :offset="[5,5]">Exportar</u-tooltip>
          </u-btn>
        </div>
        <div>

        </div>
      </div>-->
    </div>
  </div>
</template>
